.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; 
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the top */
  align-items: center;
  border-radius: var(--border-radius);
  padding: 2px 8px 4px 8px;
  text-align: center;
  font-size: var(--paragraph-font-size);
  min-height: 160px; 
}

.feature-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; /* Allow height to adjust based on content */
}

.feature-icon img {
  width: 40px !important; 
  height: 40px !important; 
  padding: 0; 
  object-fit: contain;
}

.feature-title {
  font-size: var(--subtitle-font-size);
  font-weight: bold;
  margin-bottom: 5px; 
}

.feature-description {
  color: var(--text-color);
  font-size: var(--paragraph-font-size);
  line-height: 1.4;
  letter-spacing: 0.04em;
}




@media (max-width: 768px) {
  .features-grid {
    grid-template-columns: 1fr;
    row-gap: 10px; 
    margin-bottom: 1rem;
  }
}
