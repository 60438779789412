.carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.carousel-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-items {
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth; 
  box-sizing: border-box;
  margin: 0 auto;
  
}

.carousel-item {
  flex: 0 0 100%; /* Each item takes 100% of the container width */
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  position: relative; 
  font-size: var(--paragraph-font-size);
  line-height: 1.6;
}

.carousel-items::-webkit-scrollbar {
  display: none; /* Hide scrollbar on Webkit browsers */
}

.carousel-items {
  scrollbar-width: none; /* Hide scrollbar on Firefox */
}

.image-container {
  width: 100%;
  max-width: 700px;
  height: 250px; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.use-case-text {
  width: 70%;
  margin-top: 2rem;

}

/* Modal Styles */
.zoom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeInModal 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes fadeInModal {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.zoomed-image {
  width: 90%;
  height: 90%;
  transform: translate(35%, 50%) scale(1.8); 
  justify-content: center;
}



.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  color: white;
  border: none;
  font-size: 36px;
  cursor: pointer;
}

.close-button:hover {
  color: var(--secondary-color);
}

.nav-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.scroll-arrows {
  display: flex;
  justify-content: center; 
  gap: 30px; 
  margin-bottom: 10px;
}

.arrow-bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--pink);
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  width: 20px;
  height: 20px;
  transition: opacity 0.3s ease, filter 0.3s ease;
}

.arrow.disabled {
  opacity: 0.3; 
  filter: grayscale(100%);
  pointer-events: none; 
}

.dots-container {
  display: flex;
  gap: 10px;
  align-items: center; 
  justify-content: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.active-dot {
  background-color: var(--secondary-color);
}

/* Responsive Design */
@media (max-width: 768px) {  
  .use-case-text { 
    min-width: 100%;
  }
  .image-container {
    max-width: 250px;
    height: 400px; 
  }

  .image-container {
    width: 100%;
  }

  .carousel-item {
    flex: 0 0 100%;
  }

  .carousel-image {
    height: auto;
  }
  .zoomed-image {
  
    transform: translate(25%, 50%) scale(1.5); 
    align-items: center;
  }
  
}

@media (max-width: 768px) and (max-height: 800px) {
  .image-container {
    max-width: 250px;
    height: 300px; 
  }
}
