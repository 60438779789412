.how-it-works-section {
  position: relative;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}


/* Progress and Paragraphs */
.progress-and-paragraphs {
  display: flex;
  flex-direction: column;
  margin-right: 15%;
}

.bullet-and-paragraph {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.bullet-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
}

.bullet {
  width: 16px;
  height: 16px;
  border: 2px solid var(--secondary-color);
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.bullet.active {
  background-color: var(--secondary-color);
}

.progress-line {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 1.5px;
  height: 100px; /* Placeholder, dynamically adjusted */
  background-color: transparent;
  opacity: 0.9;
  transition: opacity 0.3s ease-in-out;
}

.progress-line.filled {
  background-color: var(--lightGrey);
}

.paragraph {
  flex-grow: 1;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  font-size: var(--paragraph-font-size);
  line-height: 1.4;
  letter-spacing: 0.05em;
  color: var(--text-color);
  padding: 10px;
}

.paragraph.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .progress-and-paragraphs {
    width: 100%;
    margin-right: 0;
  }

 

  .bullet-container {
    margin-right: 0.5rem;
  }

  .intro-text p {
    width: 90%;
 
  }

  .paragraph {
    margin-left: 0;
    text-align: left;

    padding: 5px;
  }


  .buttons {
    justify-content: center;
  }
}

@media  (max-width: 768px) and  (max-height: 800px) {
  .intro-text p {
    width: 100%;  
 
  }
  .paragraph {
    margin-left: 0;
    text-align: left;
    line-height: 1.2;
    letter-spacing: 0.05em;
    padding:0px;
  }
  
}

