.footer {
  background-color: transparent;
  backdrop-filter: blur(10px);
  z-index: 100;
  color: var(--text-color);
  text-align: center;
  padding-top: 30px;
  height: var(--footer-height);
  position: relative;
  display: flex;
  justify-content: space-between; /* To spread content to the left and right */
  align-items: center;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  width: 70%;
  height: 1px; 
  background-color: var(--lightGrey);
  opacity: 0.3;
}

.footer-content {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: left;
}

.language-selector {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.language-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.language-dropdown {
  font-size: var(--footer-font-size);
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--text-color);
  cursor: pointer;
}

.footer-links {
  margin-top: 0px;
  font-size: var(--footer-font-size);
  display: flex;
  align-items: center;
}

.footer-links a,
.footer-links button {
  color: var(--text-color);
  text-decoration: none;
  margin-right: 30px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
}

.footer-links a:hover,
.footer-links button:hover {
  text-decoration: underline;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .footer {
   
    padding-top: 25px;
   
  }
 
.footer-content {
  width: 100%;
  
}

.language-selector {

  margin-right: 5px;
}

.language-icon {
  width: 20px;
  height: 20px;
  margin-right: px;
}

.language-dropdown {
  font-size: var(--footer-font-size);
 
}

.footer-links {
  margin-top: 0px;

}

.footer-links a,
.footer-links button {
 
  margin-right: 15px;

}

.footer-links a:hover,
.footer-links button:hover {
  text-decoration: underline;
}


}