.navbar {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  height: var(--navbar-height);
  background-color: transparent;
  backdrop-filter: blur(10px);
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Logo Styling */
.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  width: 140px;
  height: auto;
  margin-left: 1%;
}

/* Menu Styling */
.menu {
  display: flex;
  gap: 10px;
  align-items: center;
}

.menu li {
  list-style-type: none;
}

.menu button {
  color: var(--text-color);
  text-decoration: none;
  font-size: var(--navbar-font-size);
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  transition: all 0.3s ease;
  border-radius: var(--corner-radius);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.menu button:hover {
  background-color: var(--lighter-blue);
}

.menu button.active {
  background-color: var(--secondary-color);
  color: var(--dark-text);
}

.menu button.disabled {
  color: var(--lightGrey);
  opacity: 0.5;
  cursor: not-allowed;
}

/* Gradient Button Styling */
.menu .gradient-button {
  color: white;
  border: 2px solid transparent;
  border-image: linear-gradient(90deg, #FF7A18, #FF3CAC);
  border-image-slice: 1;
  padding: 6px 16px;
  border-radius: var(--corner-radius);
  transition: all 0.3s ease;
  cursor: pointer;
}

.menu .gradient-button:hover {
  background: linear-gradient(90deg, #FF7A18, #FF3CAC);
  color: var(--dark-text);
  border-color: currentColor;
}

.menu .gradient-button:active {
  transform: scale(0.95);
}

/* Mobile-specific dropdown */
.dropdown-mobile {
  display: none;
  position: relative;
}

.dropdown-mobile .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: var(--corner-radius);
  z-index: 101;
  display: none;
  flex-direction: column;
  width: 100%;
  background-color: var(--primary-color);
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: 100%;
}

.dropdown-mobile .dropdown-menu.open {
  display: flex;
  max-height: 120px;
}

.dropdown-mobile .dropdown-menu li {
  margin: 0;
}

.dropdown-mobile .dropdown-menu button {
  text-align: left;
  width: 100%;
  padding: 10px;
  color: var(--text-color);
  border: none;
  background: none;
}

/* Responsive adjustments */

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    padding: 10px 2%;
    width: 100%;
    height: auto;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .navbar {
    flex-direction: column;
    justify-content: center;
    padding: 8px;
  }

  .menu {
    gap: 14px;
    flex-wrap: nowrap;
  }

  .dropdown-mobile {
    display: block;
    width: 100%;
  }

  .menu > li:not(.dropdown-mobile):not(.demo-button):not(:last-child) {
    display: none;
  }
  .menu button {
    font-size: 0.8rem;
  }

  .logo {
    margin-top: 20px;
    width: 140px;
  }
}
