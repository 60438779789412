/* Global Styles */
:root {
  --primary-color: #06131f;
  --lighter-blue : #022645; 
  --secondary-color: #5e87ff;
  --tertiary-color: #fd5c02;
  --orange : #ee8747; 
  --pink : #e45a7b;
  --lightGrey: #ebebeb;
  --text-color: #FFFFFF;
  --dark-text: #333333;
  --gradient: linear-gradient(90deg, var(--orange) 0%, var(--pink) 60%);
  --corner-radius : 2px;
  --navbar-height: 40px;
  --footer-height: 15px;
  --largeTitle-font-size : 2.3rem;
  --title-font-size : 1.7rem;
  --section-title-font-size: 0.9rem;
  --intro-font-size: 1.3rem;
  --paragraph-font-size: 0.85rem;
  --navbar-font-size: 0.8rem;
  --footer-font-size: 0.7rem;

  
}

/* Responsive Font Sizes */
@media (max-width: 768px) {
  :root {
    --largeTitle-font-size: 1.9rem;
    --intro-font-size: 1.2rem;
    --title-font-size: 1.1rem;
    --navbar-font-size: 0.9rem;
    --footer-font-size: 0.7rem;
    --footer-height: 5px;
  }
}

/* Responsive Font Sizes */
@media(max-width: 768px)  and (max-height: 800px) {
  :root {
    --intro-font-size: 1.1rem;
    --title-font-size: 1rem;
   --paragraph-font-size: 0.8rem;

  }
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/Fonts/helvetica-neue/HelveticaNeueMedium.woff2') format('woff2'),
       url('./assets/Fonts/helvetica-neue/HelveticaNeueMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('./assets/Fonts/helvetica-neue/HelveticaNeueLight.woff2') format('woff2'),
       url('./assets/Fonts/helvetica-neue/HelveticaNeueLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('./assets/Fonts/helvetica-neue/HelveticaNeueBold.woff2') format('woff2'),
       url('./assets/Fonts/helvetica-neue/HelveticaNeueBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BodoniModa';
  src: url('./assets/Fonts/Bodoni-moda/BodoniModa_28pt-Black.woff') format('woff');
  src: url('./assets/Fonts/Bodoni-moda/BodoniModa_28pt-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

body {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal; 
}



.bold-text {
  font-family: 'Helvetica Neue Bold', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold; 
}

.bodoni-text {
  font-family: 'BodoniModa', serif;
  font-weight: 900;
}

.gradient-text {
  background: var(--gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  margin: 0;
  color: var(--text-color);
  overflow-x: hidden;
 
background-color: var(--primary-color);
}

.gradient, .gradient > div {
  pointer-events: none;
  overflow: hidden;
}



/* Pages container */
.pages-container {
  height: calc(100vh );
  padding: 2% 10%; 
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}


.pages-container.last-page-active {
  height: calc(100vh - var(--navbar-height) - var(--footer-height)); /* Include footer on last page */
}

.page {
  min-height: calc(100vh);
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column; 
  padding: 1rem 3rem; 
  box-sizing: border-box;
  position: relative;

}

.page.last-page {
  min-height: calc(100vh - var(--navbar-height) - var(--footer-height)); /* Include footer on last page */
}


.unique-container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin: 1% 1%;
  box-sizing: border-box;
}


/* Split Container */
.split-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 0; 
  box-sizing: border-box;
}
.left-content, .right-content {
  display: flex;
  flex-direction: column; 
  justify-content: center; 
}

.left-content {
  flex: 0 0 70%;
  padding-right: 1rem;
}

.right-content {
  flex: 0 0 30%;
  padding-left: 1rem;
  justify-content: center;
  align-items: center;
}



.title {
  font-size: var(--largeTitle-font-size);
  line-height: 1.4;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--text-color);
  text-align: left;
  z-index: 2;

}




.highlight-dot {
  display: inline-block;
  width: 0.20em;
  height: 0.20em;
  background-color: var(--pink);
  border-radius: 50%;
  margin-left: 3px;
  vertical-align: baseline;
}

/* Subtitle styles */
.subtitle {
  font-size: var(--paragraph-font-size);
  margin-bottom: 0.7rem;
  color: var(--text-color);
  opacity: 0.9;
  line-height: 1.8;
  letter-spacing: 0.05em;
   width: 80%;
}



/* Intro and Conclusion Text */
.intro-intro,
.intro-text {
  width: 100%; 
  text-align: left;
  margin: 0 ; 
}

.intro-intro {
  margin-top: 3rem; /* Add spacing above */
  margin-bottom: 1rem; 
  font-size: var(--intro-font-size);

}

.intro-text {
  margin-bottom: 2rem; 
}

.intro-text p {
  width: 70%;
  font-size: var(--title-font-size);
  color: var(--text-color);
}


/* Button styles */
.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  margin-top: 1rem;
}


.primary-button, .secondary-button {
  padding: 0.65rem 1.5rem;
  font-size: var(--paragraph-font-size);
  border: none;
  border-radius: var(--corner-radius);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.primary-button:hover, .secondary-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}


.landing-image {
  width: 100%;
  max-width: 350px; 
  height: auto;
  display: block;
  margin: 0 auto; 
}


/* Scroll Down Arrow */
.scroll-down {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 2rem;
  color: var(--text-color);
  cursor: pointer;
  z-index: 10;
}

.scroll-down:hover {
  transform: translateX(-50%) translateY(-5px);
}


@media (max-width: 768px) {
  .page { 
    padding: 4rem 0.5rem;
  }
  .landing-image {
    max-width: 100%;
  }
  .unique-container {
    width: 100%;
    margin: 0;
    padding: 0 0.5rem;
  }
  .split-container {
    flex-direction: column;
  }
  .left-content {
    flex: 0 0 100%;
    padding: 0;
    margin-top: 3rem;
    margin-bottom: 1rem;
    align-items :flex-start
  }
  .primary-button, .secondary-button {
    padding: 0.55rem 1rem;
  }
  .buttons {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    gap: 1.6rem;
  }
  .title {
    width: 110%;
  }
  .subtitle {
    width: 100%;
   margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }
  
}

@media  (max-width: 768px) and  (max-height: 800px) {
  .page { 
    padding: 3rem 0.2rem;
  }

  .title {
    line-height: 1.3;

  }
  .subtitle {
min-width: 85%  }

  
  .buttons {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 0.3rem;
    gap: 1.2rem;
  }
}
