.contact-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contact-form-container {
  background: var(--secondary-color);
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 480px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.form-title {
  font-size: 1.75rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--tertiary-color);
}

.form-intro, .form-info, .form-alternative {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: var(--text-color);
}

.form-alternativeintro {
  margin-bottom: 0rem;
  font-weight: bold;
}

.form-alternative a {
  color: var(--text-color);
  text-decoration: underline;
  margin-right: 8px;
}


.copy-button {
  background-color: var(--tertiary-color);
  border: none;
  color: #fff;
  padding: 0.4rem 0.8rem;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.copy-button:hover {
  background-color: var(--pink);
}

.copy-button.copied {
  background-color: var(--primary-color);
}


.form-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.form-group input,
.form-group textarea {
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: var(--corner-radius);
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text-color);
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--tertiary-color);
  box-shadow: 0 0 0 3px rgba(var(--tertiary-color-rgb), 0.2);
}

.form-group input.input-error,
.form-group textarea.input-error {
  border-color: #dc3545;
}

.error-text {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.success-message,
.error-message {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.success-message {
  color: var(--primary-color);
}

.error-message {
  color: #dc3545;
}

.submit-button {
  background-color: var(--tertiary-color);
  color: #fff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: var(--corner-radius);
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: var(--pink);
}

@media (max-width: 600px) {
  .contact-form-container {
    padding: 1.5rem;
  }
}
